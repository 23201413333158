import * as constants from '../constants';

const { BASE_URL, headers, fixPromise } = constants;

// регистрация
export const register = ({ email, password }) => fetch(`${BASE_URL}/signup`, {
  method: 'POST',
  headers,
  body: JSON.stringify({
    email, password,
  }),
})
  .then((res) => fixPromise(res));

// авторизация
export const login = ({ email, password }) => fetch(`${BASE_URL}/signin`, {
  method: 'POST',
  headers,
  body: JSON.stringify({
    email, password,
  }),
})
  .then((res) => fixPromise(res));

// запрос информации о пользователе
export const getUserInfo = (token) => fetch(`${BASE_URL}/users/me`, {
  method: 'GET',
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
})
  .then((res) => fixPromise(res));
