/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';

import { customAlphabet } from 'nanoid';

import '../Popup/Popup.scss';

// хук управления формой и валидации формы
import useValidForm from '../../hooks/useValidForm';

import { PLTDO_URL } from '../../utils/constants';

function PopupEditDoc({
  isDoc,
  isPopupDocEdit,
  handleEditDoc,
  isUniqueLink,
  handleClosePopupEditDoc,
  checkForUniqueLink,
  checkForUniqueDoc,
  isMassageUniqueLinkErr,
  isMassageUniqueDocErr,
  isErrorDocAPI,
  isMessageErrorDocAPI,
}) {
  const {
    values, errors, handleChange, setValues, /* isValidForm */ // resetForm,
  } = useValidForm();

  const urlAlphabet = '1234567890abcdefghijklmnopqrstuvwxyz';

  const [isShortURL, setShortURL] = useState('');

  function generateShortURL() {
    const res = customAlphabet(urlAlphabet, 6);
    const val = res;

    checkForUniqueLink(val);

    setShortURL(val);

    return val;
  }

  function handleSubmit(evt) {
    evt.preventDefault();
    generateShortURL();

    checkForUniqueDoc(values.longUrl);

    if (values.idLink) {
      checkForUniqueLink(values.idLink);
    }

    if (values.idLink) {
      if (values.idLink === '' || values.idLink === undefined) {
        const shortUrl = `${PLTDO_URL}/${isShortURL}`;
        const idLink = isShortURL;

        handleEditDoc(
          isDoc._id,
          values.name,
          values.longUrl,
          shortUrl,
          idLink,
        );
      } else {
        const shortUrl = `${PLTDO_URL}/${values.idLink}`;

        handleEditDoc(
          isDoc._id,
          values.name,
          values.longUrl,
          shortUrl,
          values.idLink,
        );
      }
    } else {
      const shortUrl = `${PLTDO_URL}/${isShortURL}`;
      const idLink = isShortURL;

      handleEditDoc(
        isDoc._id,
        values.name,
        values.longUrl,
        shortUrl,
        idLink,
      );
    }
  }

  useEffect(() => {
    setValues({
      name: isDoc.name,
      longUrl: isDoc.longUrl,
      shortUrl: isDoc.shortUrl,
      idLink: isDoc.id,
    });

    // resetForm();
    generateShortURL();

    if (!isUniqueLink) {
      generateShortURL();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDoc, setValues, isUniqueLink, isErrorDocAPI]);

  useEffect(() => {
    setValues({
      name: isDoc.name,
      longUrl: isDoc.longUrl,
      shortUrl: isDoc.shortUrl,
      idLink: isDoc.id,
    });

    generateShortURL();

    if (!isUniqueLink) {
      generateShortURL();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDoc, setValues, isUniqueLink]);

  return (
    <div
      className={isPopupDocEdit ? 'popup popup_opened' : 'popup'}
      onClick={handleClosePopupEditDoc}
    >
      <div
        className={isPopupDocEdit ? 'popup__container popup__container_opened' : 'popup__container'}
        onClick={(evt) => evt.stopPropagation()}
      >
        <h3 className="popup__title">
          Редактирование документа
        </h3>

        <form
          className="popup__form"
          onSubmit={handleSubmit}
        >

          <label
            htmlFor="popup-form-input-name"
            className="popup__form-label"
          >
            Наименование документа *
            <input
              type="text"
              name="name"
              id="popup-form-input-name"
              placeholder="Наименование документа"
              minLength="2"
              maxLength="40"
              value={values.name || ''}
              onChange={handleChange}
              className="popup__form-doc-input"
              required
            />
            <span
              className="popup__input-doc-err"
            >
              {errors.name}
            </span>
          </label>

          <label
            htmlFor="popup-form-input-longUrl"
            className="popup__form-label"
          >
            Ссылка на документ *
            <input
              type="url"
              name="longUrl"
              id="popup-form-input-longUrl"
              placeholder="https://plt.do/docs"
              minLength="2"
              value={values.longUrl || ''}
              onChange={handleChange}
              className="popup__form-doc-input"
              required
            />
            {isMassageUniqueDocErr ? (
              <span
                className="popup__input-doc-err"
              >
                {isMassageUniqueDocErr}
              </span>
            ) : (
              <span
                className="popup__input-doc-err"
              >
                {errors.longUrl}
              </span>
            )}
          </label>

          <label
            htmlFor="popup-form-input-shortUrl"
            className="popup__form-label"
          >
            Сокращенная ссылка (латинские символы и цифры)
            <input
              type="text"
              name="idLink"
              id="popup-form-input-shortUrl"
              placeholder="docs12"
              minLength="5"
              maxLength="6"
              pattern="[a-zA-Z0-9]{5,6}"
              value={values.idLink || ''}
              onChange={handleChange}
              className="popup__form-doc-input"
            />
            {isMassageUniqueLinkErr ? (
              <span
                className="popup__input-doc-err"
              >
                {isMassageUniqueLinkErr}
              </span>
            ) : (
              <span
                className="popup__input-doc-err"
              >
                {errors.idLink}
              </span>
            )}
          </label>

          <button
            type="submit"
            aria-label="Создать"
            // onClick={handleClickBtnSavePhone}
            className="popup__button-send"
          >
            Редактировать
          </button>
          {isErrorDocAPI
            ? (
              <span className="card-form__api-err">{isMessageErrorDocAPI}</span>
            ) : (
              <>
              </>
            )}

        </form>

        <button
          type="button"
          aria-label="Закрыть"
          onClick={handleClosePopupEditDoc}
          className="popup__button-close"
        />
      </div>
    </div>
  );
}

export default PopupEditDoc;