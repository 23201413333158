/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React from 'react';

import '../Popup/Popup.scss';

function PopupConfirmDoc({
  isDoc,
  handleDeleteLink,
  isPopupConfirmDoc,
  handleClosePopupConfirmDoc,
}) {
  function handleClickDeleteDoc() {
    handleDeleteLink(isDoc._id);
  }

  return (
    <div
      className={isPopupConfirmDoc ? 'popup popup_opened' : 'popup'}
      onClick={handleClosePopupConfirmDoc}
    >
      <div
        className={isPopupConfirmDoc ? 'popup__container popup__container_opened' : 'popup__container'}
        onClick={(evt) => evt.stopPropagation()}
      >
        <h3 className="popup__title">Удаление документа. Вы уверены?</h3>
        <button
          type="button"
          aria-label="Удалить"
          className="popup__btn-confirm popup__btn-confirm_type_yes"
          onClick={handleClickDeleteDoc}
        >
          Да
        </button>
        <button
          type="button"
          aria-label="Удалить"
          className="popup__btn-confirm popup__btn-confirm_type_no"
          onClick={handleClosePopupConfirmDoc}
        >
          Нет
        </button>

        <button
          type="button"
          aria-label="Закрыть"
          onClick={handleClosePopupConfirmDoc}
          className="popup__button-close"
        />
      </div>
    </div>
  );
}

export default PopupConfirmDoc;