/* eslint-disable react/prop-types */
/* eslint-disable no-console */
import React, { useEffect } /* , { useState } */ from 'react';
import { /* Link, */ useLocation } from 'react-router-dom';

import './FormAuth.scss';

// хук управления формой и валидации формы
import useValidForm from '../../hooks/useValidForm';

function FormAuth({
  onRegister,
  onLogin,
  children,
  title,
  buttonText,
  isErrorAPI,
  isMessageErrorAPI,
  isDisabledInput,
}) {
  const location = useLocation();
  const path = location.pathname;

  const {
    values, errors, handleChange, isValidForm, resetForm,
  } = useValidForm();

  function handleSubmit(evt) {
    evt.preventDefault();

    // если пользователь на странице входа
    if (path === '/signin') {
      // запустить функцию входа
      onLogin(
        values.email,
        values.password,
      );
    }

    // если пользователь на странице регистрации
    if (path === '/signup') {
      // запустить функцию регистрации
      onRegister(
        values.email,
        values.password,
      );
    }
  }

  useEffect(() => {
    resetForm();
  }, [resetForm]);

  return (
    <section
      className="auth"
    >
      <div className="auth__container">
        <h2 className="title">
          {title}
        </h2>
        <form
          onSubmit={handleSubmit}
          className="auth__form"
        >
          <label
            htmlFor="auth-input-email"
            className="auth__label auth__text"
          >
            E-mail
            <input
              type="email"
              name="email"
              id="auth-input-email"
              placeholder="E-mail"
              minLength="5"
              value={values.email || ''}
              className={`auth__input auth__text ${isDisabledInput ? 'auth__input_disabled' : ''}`}
              onChange={handleChange}
              disabled={isDisabledInput}
              required
            />
            <span
              className="auth__input-err auth__text"
            >
              {errors.email}
            </span>
          </label>

          <label
            htmlFor="auth-input-password"
            className="auth__label auth__text"
          >
            Пароль
            <input
              type="password"
              name="password"
              id="auth-input-password"
              placeholder="Пароль"
              minLength="8"
              value={values.password || ''}
              className={`auth__input auth__text ${isDisabledInput ? 'auth__input_disabled' : ''}`}
              onChange={handleChange}
              disabled={isDisabledInput}
              required
            />
            <span
              className="auth__input-err auth__text"
            >
              {errors.password}
            </span>
          </label>

          {children}

          <div
            className="auth__wrap"
          >
            {isErrorAPI
              ? (
                <span className="auth__api-err auth__text">{isMessageErrorAPI}</span>
              ) : (
                <>
                </>
              )}
            <button
              type="submit"
              aria-label={buttonText}
              className={`auth__btn-submit
              ${!isValidForm ? 'auth__btn-submit_disabled' : ''}
              }`}
              disabled={!isValidForm}
            >
              {buttonText}
            </button>
          </div>

          {
            // убрать, потому что функция регистрации не нужна
            /*
          <p
            className="auth__paragraph auth__text"
          >
            {question}
            <Link
              to={link}
              className="auth__link"
            >
              {textLink}
            </Link>
          </p>
            */
          }

        </form>
      </div>
    </section>
  );
}

export default FormAuth;