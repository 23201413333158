/* eslint-disable react/prop-types */
import React from 'react';

import '../UsersTable/UsersTable.scss';

import DocsList from '../DocsList/DocsList';

function DocsTable({
  docsCards,
  handleOpenPopupEditDoc,
  handleOpenPopupDocQR,
  handleOpenPopupConfirmDoc,
  handleOpenInfoToolTip,
  handleCloseInfoToolTip,
  handleInfoToolTipMessage,
}) {
  return (
    <table className="users-table users-table_type_doc">
      <thead className="users-table__head">
        <tr className="users-table__tr users-table__tr_type_head">
          <th className="users-table__th-head">Ссылка</th>
          <th className="users-table__th-head">Наименование документа</th>
          <th className="users-table__th-head">QR-код</th>
          <th className="users-table__th-head">Действия</th>
        </tr>
      </thead>

      <tbody className="users-table__body">
        <DocsList
          docsCards={docsCards}
          handleOpenPopupEditDoc={handleOpenPopupEditDoc}
          handleOpenPopupDocQR={handleOpenPopupDocQR}
          handleOpenPopupConfirmDoc={handleOpenPopupConfirmDoc}
          handleOpenInfoToolTip={handleOpenInfoToolTip}
          handleCloseInfoToolTip={handleCloseInfoToolTip}
          handleInfoToolTipMessage={handleInfoToolTipMessage}
        />
      </tbody>
    </table>
  );
}

export default DocsTable;

/*
        <tr className="users-table__tr users-table__tr_type_body">
          <td className="users-table__td-body users-table__td-body_type_link">
            <Link
              to="/"
              target="_blank"
              rel="noopener noreferrer"
              className="users-table__link"
            >
              Ссылка
            </Link>
          </td>
          <td className="users-table__td-body">Наименование документа</td>
          <td className="users-table__td-body">
            <div className="users-table__td-body_buttons">
              <button
                aria-label="button"
                type="button"
                className="users-table__btn users-table__btn_type_qr"
              >
                Показать
              </button>
            </div>
          </td>
          <td className="users-table__td-body">
            <div className="users-table__td-body_buttons">
              <button
                aria-label="button"
                type="button"
                className="users-table__btn users-table__btn_type_edit"
              />
              <button
                aria-label="button"
                type="button"
                className="users-table__btn users-table__btn_type_delete"
              />
            </div>
          </td>
        </tr>
*/