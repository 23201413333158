/* eslint-disable prefer-promise-reject-errors */
export const PLTDO_URL = 'http://plt.do';
// export const PLTDO_URL = 'http://localhost:3000';

// url бэкенда
// export const BASE_URL = 'http://localhost:4000';
export const BASE_URL = 'https://api.plt.do';

export const VCF_URL = 'uploads/vcf';
export const LOGO_URL = 'uploads/img/logo-ava.png';

// заголовки
export const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

// фиксация промиса
export const fixPromise = (res) => (
  res.ok ? res.json()
    : Promise.reject(`Произошла ошибка ${res.status}: ${res.statusText}`)
);

export const version = '1.2.5 (2022-01-21)';
