/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { customAlphabet } from 'nanoid';

import './FormUsers.scss';
import { BASE_URL, VCF_URL, LOGO_URL } from '../../utils/constants';

import useValidForm from '../../hooks/useValidForm';

function FormUsers({
  handleCreateCard,
  handleEditCard,
  title,
  buttonText,
  isErrorAPI,
  isMessageErrorAPI,
  isErrLink,
  isMessageErrLink,
  isDisabledInput,
  card,
}) {
  const location = useLocation();
  const path = location.pathname;

  // стейт загруженной аватарки
  const [isImg, setImg] = useState(null);
  // const [isAvatar, setAvatar] = useState(null);
  const [isShortURL, setShortURL] = useState('');
  const [avatarLogo, setAvatarLogo] = useState('');

  const [isDisabledBtnForm, setDisabledBtnForm] = useState(true);
  const [isUpdateAvatar, setUpdateAvatar] = useState(false);

  const {
    values, setValues, errors, handleChange, isValidForm, resetForm,
  } = useValidForm();

  const avatarUpload = document.getElementById('users-input-upload');
  // const avatarPreview = document.getElementById('avatar-preview');
  const urlAlphabet = '1234567890abcdefghijklmnopqrstuvwxyz';

  function uploadFile(file) {
    // проверить тип файла
    if (!['image/jpeg', 'image/png'].includes(file.type)) {
      console.log('Неверный формат изображения, только jpg или png');
      errors.avatar = 'Неверный формат изображения, только jpg или png';
      avatarUpload.value = '';
    }

    if (file.size > 1024 * 1024 * 2) {
      console.log('Файл должен быть менее 2 МБ');
      errors.avatar = 'Файл должен быть менее 2 МБ';
    }

    const reader = new FileReader();
    reader.onload = function (evt) {
      setAvatarLogo(`${evt.target.result}`);
    };
    reader.onerror = function () {
      console.log('Ошибка');
    };
    reader.readAsDataURL(file);
  }

  function handleChangeAvatar(evt) {
    // загрузить на страницу
    uploadFile(avatarUpload.files[0]);

    // положить img в стейт для дальнейшей отправки в БД
    setImg(evt.target.files[0]);

    setUpdateAvatar(true);
  }

  const handleSendForm = (evt) => {
    evt.preventDefault();

    const formData = new FormData();
    if (isImg) { formData.append('avatar', isImg); }
    if (values.surname) { formData.append('surname', values.surname); }
    if (values.name) { formData.append('name', values.name); }
    if (values.patronymic) { formData.append('patronymic', values.patronymic); }
    if (values.organization) { formData.append('organization', values.organization); }
    if (values.position) { formData.append('position', values.position); }
    if (values.phoneWork) { formData.append('phoneWork', values.phoneWork); }
    if (values.phoneAdd1) { formData.append('phoneAdd1', values.phoneAdd1); }
    if (values.phoneAdd2) { formData.append('phoneAdd2', values.phoneAdd2); }
    if (values.site) { formData.append('site', values.site); }
    if (values.email) { formData.append('email', values.email); }
    if (values.emailAdd) { formData.append('emailAdd', values.emailAdd); }

    if (!values.link) {
      formData.append('link', isShortURL);
      formData.append('vcf', `${VCF_URL}/${isShortURL}.vcf`);
    } else {
      formData.append('link', values.link);
      formData.append('vcf', `${VCF_URL}/${values.link}.vcf`);
    }

    if (values.country) { formData.append('country', values.country); }
    if (values.region) { formData.append('region', values.region); }
    if (values.city) { formData.append('city', values.city); }
    if (values.address) { formData.append('address', values.address); }
    if (values.info) { formData.append('info', values.info); }

    if (path === '/create') {
      handleCreateCard(formData);

      resetForm();
    }

    if (path === '/edit') {
      handleEditCard(formData, card._id);

      resetForm();
    }
  };

  useEffect(() => {
    const shortURL = customAlphabet(urlAlphabet, 4);

    const value = shortURL;

    setShortURL(value);

    // если форма валидна
    if (isValidForm) {
      // снять Disabled кнопки сабмита формы
      setDisabledBtnForm(false);
    }

    if (path === '/create') {
      setAvatarLogo(`${BASE_URL}/uploads/img/logo-ava.png`);
    }

    // если на странице редактирования
    if (path === '/edit') {
      // заполнить инпуты карточки
      setValues({
        surname: card.surname,
        name: card.name,
        patronymic: card.patronymic,
        organization: card.organization,
        position: card.position,
        phoneWork: card.phoneWork,
        phoneAdd1: card.phoneAdd1,
        phoneAdd2: card.phoneAdd2,
        site: card.site,
        email: card.email,
        emailAdd: card.emailAdd,
        link: card.link,
        country: card.country,
        region: card.region,
        city: card.city,
        address: card.address,
        info: card.info,
      });

      if (
        card.avatar === 'https://polati.ru/wp-content/uploads/2021/06/favicon.svg'
        || card.avatar === LOGO_URL
      ) {
        setAvatarLogo(`${BASE_URL}/${LOGO_URL}`);
      } else {
        setAvatarLogo(`${BASE_URL}/${card.avatar}`);
        setUpdateAvatar(true);
      }

      // если состояние аватара изменено
      if (isUpdateAvatar) {
        // снять Disabled кнопки сабмита формы
        setDisabledBtnForm(false);
      }
    }
  }, [card, path, setValues, isValidForm, isUpdateAvatar]);

  return (
    <section
      className="card-form"
    >
      <div className="card-form__container">
        <h2 className="title">
          {title}
        </h2>

        <form
          id="form"
          encType="multipart/form-data"
          method="post"
          onSubmit={handleSendForm}
          className="card-form__form"
        >

          <div className="card-form__wrap-inputs">
            <div className="card-form__wrap-head">

              <div className="card-form__wrap-avatar">
                <div
                  className={isUpdateAvatar
                    ? 'card-form__avatar card-form__avatar_type_loaded'
                    : 'card-form__avatar'}
                >
                  <div
                    className={isUpdateAvatar
                      ? 'card-form__image card-form__image_type_loaded'
                      : 'card-form__image'}
                    id="avatar-preview"
                    style={{ backgroundImage: `url(${avatarLogo})` }}
                  />
                </div>
                <div className="card-form__upload">
                  <label
                    htmlFor="users-input-upload"
                    className="card-form__label-upload"
                  >
                    Загрузить
                    <input
                      type="file"
                      accept=".jpg, .png"
                      name="file"
                      id="users-input-upload"
                      className="card-form__input-upload hide-el"
                      onChange={handleChangeAvatar}
                      disabled={isDisabledInput}
                    />
                    <span
                      className="card-form__input-upload-err"
                    >
                      {errors.avatar}
                    </span>
                  </label>
                </div>
              </div>

              <div className="card-form__wrap-fullname">
                <label
                  htmlFor="card-form-input-surname"
                  className="card-form__label"
                >
                  Фамилия *
                  <input
                    type="text"
                    name="surname"
                    id="card-form-input-surname"
                    placeholder="Фамилия"
                    minLength="2"
                    value={values.surname || ''}
                    className={`card-form__input
                    ${isDisabledInput ? 'card-form__input_disabled' : ''}`}
                    onChange={handleChange}
                    disabled={isDisabledInput}
                    required
                  />
                  <span
                    className="card-form__input-err"
                  >
                    {errors.surname}
                  </span>
                </label>

                <label
                  htmlFor="card-form-input-name"
                  className="card-form__label"
                >
                  Имя *
                  <input
                    type="text"
                    name="name"
                    id="card-form-input-name"
                    placeholder="Имя"
                    minLength="2"
                    value={values.name || ''}
                    className={`card-form__input
                    ${isDisabledInput ? 'card-form__input_disabled' : ''}`}
                    onChange={handleChange}
                    disabled={isDisabledInput}
                    required
                  />
                  <span
                    className="card-form__input-err"
                  >
                    {errors.name}
                  </span>
                </label>

                <label
                  htmlFor="card-form-input-patronymic"
                  className="card-form__label"
                >
                  Отчество
                  <input
                    type="text"
                    name="patronymic"
                    id="card-form-input-patronymic"
                    placeholder="Отчество"
                    value={values.patronymic || ''}
                    className={`card-form__input
                    ${isDisabledInput ? 'card-form__input_disabled' : ''}`}
                    onChange={handleChange}
                    disabled={isDisabledInput}
                  />
                  <span
                    className="card-form__input-err"
                  >
                    {errors.patronymic}
                  </span>
                </label>
              </div>
            </div>

            <div className="card-form__wrap-items">
              <label
                htmlFor="card-form-input-organization"
                className="card-form__label card-form__label_type_column-two"
              >
                Наименование организации *
                <input
                  type="text"
                  name="organization"
                  id="card-form-input-organization"
                  placeholder="Наименование организации"
                  minLength="2"
                  value={values.organization || ''}
                  className={`card-form__input
                  ${isDisabledInput ? 'card-form__input_disabled' : ''}`}
                  onChange={handleChange}
                  disabled={isDisabledInput}
                  required
                />
                <span
                  className="card-form__input-err"
                >
                  {errors.organization}
                </span>
              </label>

              <label
                htmlFor="card-form-input-position"
                className="card-form__label card-form__label_type_column-two"
              >
                Должность *
                <input
                  type="text"
                  name="position"
                  id="card-form-input-position"
                  placeholder="Должность"
                  minLength="2"
                  value={values.position || ''}
                  className={`card-form__input
                  ${isDisabledInput ? 'card-form__input_disabled' : ''}`}
                  onChange={handleChange}
                  disabled={isDisabledInput}
                  required
                />
                <span
                  className="card-form__input-err"
                >
                  {errors.position}
                </span>
              </label>
            </div>

            <div className="card-form__wrap-items">
              <label
                htmlFor="card-form-input-phoneWork"
                className="card-form__label card-form__label_type_column-three"
              >
                Основной телефон *
                <input
                  type="tel"
                  name="phoneWork"
                  id="card-form-input-phoneWork"
                  placeholder="+7 (999) 999-99-99"
                  value={values.phoneWork || ''}
                  className={`card-form__input
                  ${isDisabledInput ? 'card-form__input_disabled' : ''}`}
                  onChange={handleChange}
                  disabled={isDisabledInput}
                  required
                />
                <span
                  className="card-form__input-err"
                >
                  {errors.phoneWork}
                </span>
              </label>

              <label
                htmlFor="card-form-input-phoneAdd1"
                className="card-form__label card-form__label_type_column-three"
              >
                Дополнительный телефон
                <input
                  type="tel"
                  name="phoneAdd1"
                  id="card-form-input-phoneAdd1"
                  placeholder="+7 (999) 999-99-99"
                  value={values.phoneAdd1 || ''}
                  className={`card-form__input
                  ${isDisabledInput ? 'card-form__input_disabled' : ''}`}
                  onChange={handleChange}
                  disabled={isDisabledInput}
                />
                <span
                  className="card-form__input-err"
                >
                  {errors.phoneAdd1}
                </span>
              </label>

              <label
                htmlFor="card-form-input-phoneAdd2"
                className="card-form__label card-form__label_type_column-three"
              >
                Дополнительный телефон
                <input
                  type="tel"
                  name="phoneAdd2"
                  id="card-form-input-phoneAdd2"
                  placeholder="+7 (999) 999-99-99"
                  value={values.phoneAdd2 || ''}
                  className={`card-form__input
                  ${isDisabledInput ? 'card-form__input_disabled' : ''}`}
                  onChange={handleChange}
                  disabled={isDisabledInput}
                />
                <span
                  className="card-form__input-err"
                >
                  {errors.phoneAdd2}
                </span>
              </label>
            </div>

            <div className="card-form__wrap-items">
              <label
                htmlFor="card-form-input-site"
                className="card-form__label card-form__label_type_column-two"
              >
                Сайт
                <input
                  type="text"
                  name="site"
                  id="card-form-input-site"
                  placeholder="www.site.com"
                  value={values.site || ''}
                  className={`card-form__input
                  ${isDisabledInput ? 'card-form__input_disabled' : ''}`}
                  onChange={handleChange}
                  disabled={isDisabledInput}
                />
                <span
                  className="card-form__input-err"
                >
                  {errors.site}
                </span>
              </label>

              <label
                htmlFor="card-form-input-link"
                className="card-form__label card-form__label_type_column-two"
              >
                Ссылка на визитку
                <input
                  type="text"
                  name="link"
                  id="card-form-input-link"
                  minLength="1"
                  maxLength="4"
                  placeholder="asd1"
                  value={values.link || ''}
                  className={`card-form__input
                  ${isDisabledInput ? 'card-form__input_disabled' : ''}`}
                  onChange={handleChange}
                  disabled={isDisabledInput}
                />
                <span
                  className="card-form__input-err"
                >
                  {errors.link}
                </span>
                {isErrLink
                  ? (
                    <span
                      className="card-form__input-err"
                    >
                      {isMessageErrLink}
                    </span>
                  ) : (
                    <>
                    </>
                  )}
              </label>
            </div>

            <div className="card-form__wrap-items">
              <label
                htmlFor="card-form-input-email"
                className="card-form__label card-form__label_type_column-two"
              >
                Электронная почта
                <input
                  type="email"
                  name="email"
                  id="card-form-input-email"
                  placeholder="email@mail.com"
                  value={values.email || ''}
                  className={`card-form__input
                  ${isDisabledInput ? 'card-form__input_disabled' : ''}`}
                  onChange={handleChange}
                  disabled={isDisabledInput}
                />
                <span
                  className="card-form__input-err"
                >
                  {errors.email}
                </span>
              </label>

              <label
                htmlFor="card-form-input-emailAdd"
                className="card-form__label card-form__label_type_column-two"
              >
                Дополнительная электронная почта
                <input
                  type="emailAdd"
                  name="emailAdd"
                  id="card-form-input-emailAdd"
                  placeholder="email@mail.com"
                  value={values.emailAdd || ''}
                  className={`card-form__input
                  ${isDisabledInput ? 'card-form__input_disabled' : ''}`}
                  onChange={handleChange}
                  disabled={isDisabledInput}
                />
                <span
                  className="card-form__input-err"
                >
                  {errors.emailAdd}
                </span>
              </label>
            </div>

            <div className="card-form__wrap-items">
              <label
                htmlFor="card-form-input-country"
                className="card-form__label card-form__label_type_column-two"
              >
                Страна
                <input
                  type="text"
                  name="country"
                  id="card-form-input-country"
                  placeholder="Страна"
                  value={values.country || ''}
                  className={`card-form__input
                  ${isDisabledInput ? 'card-form__input_disabled' : ''}`}
                  onChange={handleChange}
                  disabled={isDisabledInput}
                />
                <span
                  className="card-form__input-err"
                >
                  {errors.country}
                </span>
              </label>

              <label
                htmlFor="card-form-input-region"
                className="card-form__label card-form__label_type_column-two"
              >
                Область
                <input
                  type="text"
                  name="region"
                  id="card-form-input-region"
                  placeholder="Область"
                  value={values.region || ''}
                  className={`card-form__input
                  ${isDisabledInput ? 'card-form__input_disabled' : ''}`}
                  onChange={handleChange}
                  disabled={isDisabledInput}
                />
                <span
                  className="card-form__input-err"
                >
                  {errors.region}
                </span>
              </label>
            </div>

            <div className="card-form__wrap-items">
              <label
                htmlFor="card-form-input-city"
                className="card-form__label card-form__label_type_column-two"
              >
                Город
                <input
                  type="text"
                  name="city"
                  id="card-form-input-city"
                  placeholder="Город"
                  value={values.city || ''}
                  className={`card-form__input
                  ${isDisabledInput ? 'card-form__input_disabled' : ''}`}
                  onChange={handleChange}
                  disabled={isDisabledInput}
                />
                <span
                  className="card-form__input-err"
                >
                  {errors.city}
                </span>
              </label>

              <label
                htmlFor="card-form-input-address"
                className="card-form__label card-form__label_type_column-two"
              >
                Адрес
                <input
                  type="text"
                  name="address"
                  id="card-form-input-address"
                  placeholder="Улица, дом, корпус, офис/квартира"
                  value={values.address || ''}
                  className={`card-form__input
                  ${isDisabledInput ? 'card-form__input_disabled' : ''}`}
                  onChange={handleChange}
                  disabled={isDisabledInput}
                />
                <span
                  className="card-form__input-err"
                >
                  {errors.address}
                </span>
              </label>
            </div>

            <label
              htmlFor="card-form-textarea-info"
              className="card-form__label card-form__label_type_info"
            >
              Дополнительная информация
              <textarea
                name="info"
                id="card-form-textarea-info"
                placeholder="Дополнительная информация"
                cols="30"
                rows="10"
                value={values.info || ''}
                className={`card-form__input card-form__input_type_textarea
                ${isDisabledInput ? 'card-form__input_disabled' : ''}`}
                onChange={handleChange}
                disabled={isDisabledInput}
              />
              <span
                className="card-form__info-err"
              >
                {errors.info}
              </span>
            </label>
          </div>

          <div className="card-form__wrap-btn">
            <button
              type="submit"
              aria-label={buttonText}
              className={`card-form__btn-submit
              ${isDisabledBtnForm ? 'card-form__btn-submit_disabled' : ''}
              `}
              // disabled={!isValidForm} isDisabledBtnForm
              disabled={isDisabledBtnForm}
            >
              {buttonText}
            </button>
            {isErrorAPI
              ? (
                <span className="card-form__api-err">{isMessageErrorAPI}</span>
              ) : (
                <>
                </>
              )}

            <Link
              // to="/main"
              to="/"
              className="card-form__link"
            >
              На главную
            </Link>
          </div>

        </form>
      </div>
    </section>
  );
}

export default FormUsers;