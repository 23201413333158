/* eslint-disable react/prop-types */
import React from 'react';

import FormAuth from '../FormAuth/FormAuth';

function Login({
  onLogin,
  onSubmit,
  sendingData,
  messageSendingData,
  isErrorAPI,
  isMessageErrorAPI,
  isDisabledInput,
}) {
  return (
    <FormAuth
      onLogin={onLogin}
      onSubmit={onSubmit}
      title="Вход"
      buttonText={sendingData ? messageSendingData : 'Войти'}
      question="Еще не зарегистрированы? &nbsp;"
      link="/signup"
      textLink="Регистрация"
      isErrorAPI={isErrorAPI}
      isMessageErrorAPI={isMessageErrorAPI}
      isDisabledInput={isDisabledInput}
    >

      <label
        htmlFor="auth-input-checkbox"
        className="auth__label-checkbox"
      >
        <input
          type="checkbox"
          name="checkbox"
          id="auth-input-checkbox"
          className="auth__input-checkbox auth__text"
        />
        <span className="auth__span-checkbox" />
        Запомнить меня
      </label>

    </FormAuth>
  );
}

export default Login;