import React from 'react';

import './Preloader.scss';

import logo from '../../images/logo/logo-min.png';

export default function Preloader() {
  return (
    <div className="preloader" id="preloader">
      <img
        src={logo}
        alt="Логотип"
        className="preloader__logo"
      />
    </div>
  );
}