/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React from 'react';

import '../Popup/Popup.scss';

function PopupQR({
  isPopupOpenQR,
  onPopupCloseQR,
}) {
  // вытащить имя/фамилию из localStorage
  const nameForQR = localStorage.getItem('nameForQR');
  const roleForQR = localStorage.getItem('roleForQR');

  return (
    <div
      className={isPopupOpenQR ? 'popup popup_opened' : 'popup'}
      onClick={onPopupCloseQR}
    >
      <div
        className={isPopupOpenQR
          ? 'popup__container popup__container_type_QR popup__container_opened'
          : 'popup__container popup__container_type_QR'}
        onClick={(evt) => evt.stopPropagation()}
      >
        <h3 className="popup__title popup__title_type_qr">{nameForQR}</h3>
        <p className="popup__subtitle popup__subtitle_type_qr">{roleForQR}</p>
        <canvas id="qr" className="popup__qr" />
        <button
          type="button"
          aria-label="Закрыть"
          onClick={onPopupCloseQR}
          className="popup__button-close"
        />
      </div>
    </div>
  );
}

export default PopupQR;