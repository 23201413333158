/* eslint-disable react/prop-types */
import React from 'react';

import FormUsers from '../FormUsers/FormUsers';

function EditCard({
  onSubmit,
  handleEditCard,
  sendingData,
  messageSendingData,
  isErrorAPI,
  isMessageErrorAPI,
  isErrLink,
  isMessageErrLink,
  isDisabledInput,
}) {
  const card = JSON.parse(localStorage.getItem('card'));

  return (
    <FormUsers
      handleEditCard={handleEditCard}
      onSubmit={onSubmit}
      title="Редактирование карточки"
      buttonText={sendingData ? messageSendingData : 'Редактировать'}
      isErrorAPI={isErrorAPI}
      isMessageErrorAPI={isMessageErrorAPI}
      isErrLink={isErrLink}
      isMessageErrLink={isMessageErrLink}
      isDisabledInput={isDisabledInput}
      // чтобы получить данный сделать запрос API
      card={card}
    />
  );
}

export default EditCard;