/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
/* eslint-disable no-console */
import React from 'react';

function DocCard({
  doc,
  handleOpenPopupEditDoc,
  handleOpenPopupDocQR,
  handleOpenPopupConfirmDoc,
  handleOpenInfoToolTip,
  handleCloseInfoToolTip,
  handleInfoToolTipMessage,
}) {
  function handleClickEditDoc() {
    handleOpenPopupEditDoc(doc);
  }

  function handleClickDocQR() {
    handleOpenPopupDocQR(doc);
  }

  function handleClickDeleteDoc() {
    handleOpenPopupConfirmDoc(doc);
  }

  function fallbackCopyTextToClipboard(text) {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand('copy');
      const msg = successful ? 'successful' : 'unsuccessful';
      console.log(`Fallback: Copying text command was ${msg}`);
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
  }

  function copyTextToClipboard(text) {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text);
      return;
    }

    navigator.clipboard.writeText(text)
      .then((res) => {
        handleInfoToolTipMessage('Ссылка скопирована!');
        console.log('Async: Copying to clipboard was successful!');
        
        handleOpenInfoToolTip();

        setTimeout(() => {
          handleCloseInfoToolTip();
        }, 1500);

        return res;
      })
      .catch((err) => {
        handleInfoToolTipMessage('Не удалось скопировать ссылку, попробуйте еще раз!');
        console.error('Async: Could not copy text: ', err);

        handleOpenInfoToolTip();

        setTimeout(() => {
          handleCloseInfoToolTip();
        }, 1500);
      });
  }

  function handleClickCopyLink() {
    copyTextToClipboard(`${doc.shortUrl}`);
  }

  return (
    <>
      <tr className="users-table__tr users-table__tr_type_body">
        <td className="users-table__td-body users-table__td-body_type_link">
          <button
            type="button"
            onClick={handleClickCopyLink}
            className="users-table__link"
          >
            {doc.id}
          </button>
        </td>
        <td className="users-table__td-body">{doc.name}</td>
        <td className="users-table__td-body">
          <div className="users-table__td-body_buttons">
            <button
              aria-label="button"
              type="button"
              onClick={handleClickDocQR}
              className="users-table__btn users-table__btn_type_qr"
            >
              Показать
            </button>
          </div>
        </td>
        <td className="users-table__td-body">
          <div className="users-table__td-body_buttons">
            <a
              // type="button"
              aria-label="Ссылка"
              href={doc.longUrl}
              target="_blank"
              rel="noopener noreferrer"
              // onClick={handleClickCopyLink}
              className="users-table__btn users-table__btn_type_link"
            />
            <button
              aria-label="button"
              type="button"
              onClick={handleClickEditDoc}
              className="users-table__btn users-table__btn_type_edit"
            />
            <button
              aria-label="button"
              type="button"
              onClick={handleClickDeleteDoc}
              className="users-table__btn users-table__btn_type_delete"
            />
          </div>
        </td>
      </tr>
    </>
  );
}

export default DocCard;
