/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import './Card.scss';

import Popup from '../Popup/Popup';

import { BASE_URL } from '../../utils/constants';

function Card({
  loggedIn,
  card,
  handleGetCardByLink,
  handleSendVCardToEmail,
}) {
  const location = useLocation();
  // стейт открытия popup
  const [isPopup, setPopup] = useState(false);
  // стейт страницы попапа с формой
  const [isPageFormPopup, setPageFormPopup] = useState(false);

  // const avatarDefault = 'uploads/img/logo-default.png';

  // деструктурировать card
  const {
    surname,
    name,
    organization,
    position,
    phoneWork,
    phoneAdd1,
    phoneAdd2,
    site,
    email,
    emailAdd,
    country,
    region,
    city,
    address,
    info,
  } = card;

  const [avatarIMG, setAvatarIMG] = useState('');

  function handleClickBtn() {
    setPopup(true);
  }

  function handleClosePopup() {
    setPopup(false);
    setPageFormPopup(false);
  }

  function handlePageFormPopupOpen() {
    setPageFormPopup(true);
  }

  function handlePageFormPopupClose() {
    setPageFormPopup(false);
  }

  useEffect(() => {
    // получить местонахождение
    const path = location.pathname;
    // вытащить ссылку из местонахождения
    const link = path.substr(1);

    // если пользователь не залогинен
    if (!loggedIn) {
      // достать данные карточки по короткой ссылке
      handleGetCardByLink(link);
    }

    if (
      card.avatar === 'https://polati.ru/wp-content/uploads/2021/06/favicon.svg'
      || card.avatar === 'uploads/img/logo-ava.png'
    ) {
      setAvatarIMG(`${BASE_URL}/uploads/img/logo-default.png`);
    } else {
      setAvatarIMG(`${BASE_URL}/${card.avatar}`);
    }
  }, [card, loggedIn]);

  return (
    <>
      <section
        className="card"
      >
        <div
          className="card__head"
        >
          <div className="card__wrap-head card__width">
            <div
              className={
                (card.avatar === 'https://polati.ru/wp-content/uploads/2021/06/favicon.svg'
                  || card.avatar === 'uploads/img/logo-ava.png')
                  ? 'card__avatar card__avatar_type_default'
                  : 'card__avatar card__avatar_type_photo'
              }
              style={{ backgroundImage: `url(${avatarIMG})` }}
            />
            <h2 className="card__title">
              {`${name}`}
              &nbsp;
              {`${surname}`}
            </h2>
          </div>

          <div
            className="card__links-head card__width"
          >
            <a
              href={`tel:${phoneWork}`}
              className="card__link-head card__link-head_type_tel"
            >
              Позвонить
            </a>

            <a
              href={`mailto:${email}`}
              className="card__link-head card__link-head_type_mail"
            >
              Отправить E-mail
            </a>
          </div>
        </div>

        <div
          className="card__contacts card__width"
        >
          <a
            href={`tel:${phoneWork}`}
            className="card__link-contacts card__link-contacts_type_tel"
          >
            <p className="card__contact">
              {`${phoneWork}`}
            </p>
            <p className="card__link-span">
              Основной телефон
            </p>
          </a>

          {phoneAdd1 === null || phoneAdd1 === '' || phoneAdd1 === 0 || phoneAdd1 === undefined ? (
            <>
            </>
          ) : (
            <a
              href={`tel:${phoneAdd1}`}
              className="card__link-contacts card__link-contacts_type_tel"
            >
              <p className="card__contact">
                {`${phoneAdd1}`}
              </p>
              <p className="card__link-span">
                Дополнительный телефон
              </p>
            </a>
          )}

          {phoneAdd2 === null || phoneAdd2 === '' || phoneAdd2 === 0 || phoneAdd2 === undefined ? (
            <>
            </>
          ) : (
            <a
              href={`tel:${phoneAdd2}`}
              className="card__link-contacts card__link-contacts_type_tel"
            >
              <p className="card__contact">
                {`${phoneAdd2}`}
              </p>
              <p className="card__link-span">
                Дополнительный телефон
              </p>
            </a>
          )}

          {email === null || email === '' || email === 0 || email === undefined ? (
            <>
            </>
          ) : (
            <a
              href={`mailto:${email}`}
              className="card__link-contacts card__link-contacts_type_email"
            >
              <p className="card__contact">
                {`${email}`}
              </p>
              <p className="card__link-span">
                E-mail
              </p>
            </a>
          )}

          {emailAdd === null || emailAdd === '' || emailAdd === 0 || emailAdd === undefined ? (
            <>
            </>
          ) : (
            <a
              href={`mailto:${emailAdd}`}
              className="card__link-contacts card__link-contacts_type_email"
            >
              <p className="card__contact">
                {`${emailAdd}`}
              </p>
              <p className="card__link-span">
                Дополнительный E-mail
              </p>
            </a>
          )}

          <div className="card__link-contacts">
            <p className="card__contact">
              {`${position}`}
            </p>
            <p className="card__link-span">
              {`${organization}`}
            </p>
          </div>

          {site === null || site === '' || site === 0 || site === undefined ? (
            <>
            </>
          ) : (
            <a
              href={`http://${site}`}
              target="_blank"
              rel="noopener noreferrer"
              className="card__link-contacts card__link-contacts_type_email"
            >
              <p className="card__contact">
                {site}
              </p>
              <p className="card__link-span">
                Сайт
              </p>
            </a>
          )}

          {address === null || address === '' || address === 0 || address === undefined
            ? (
              <>
              </>
            ) : (
              <div className="card__link-contacts">
                <p className="card__contact">
                  {`${country}, ${region}, ${city}, ${address}`}
                </p>
                <p className="card__link-span">
                  Адрес
                </p>
              </div>
            )}

          {info === null || info === '' || info === 0 || info === undefined
            ? (
              <>
              </>
            ) : (
              <div className="card__link-contacts">
                <p className="card__contact">
                  {`${info}`}
                </p>
                <p className="card__link-span">
                  Дополнительная информация
                </p>
              </div>
            )}

        </div>

        <button
          type="button"
          onClick={handleClickBtn}
          className="users__btn"
        >
          {
            // ! ниже можно изменить иконку
          }
          <div className="users__btn_logo users__btn_logo_user-add" />
        </button>
      </section>

      <Popup
        isPopupOpen={isPopup}
        onClosePopup={handleClosePopup}
        isPageFormPopup={isPageFormPopup}
        handlePageFormPopupOpen={handlePageFormPopupOpen}
        handlePageFormPopupClose={handlePageFormPopupClose}
        handleSendVCardToEmail={handleSendVCardToEmail}
        card={card}
      />

    </>
  );
}

export default Card;