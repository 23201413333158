/* eslint-disable react/prop-types */
import React from 'react';

function NotFound() {
  return (
    <h2 className="title">
      Cервис компании ПОЛАТИ
    </h2>
  );
}

export default NotFound;