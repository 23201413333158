/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
/* eslint-disable no-console */
import React, { useState } from 'react';
// import { useHistory } from 'react-router-dom';

import '../Users/Users.scss';

import DocsTable from '../DocsTable/DocsTable';
import PopupAddDoc from '../PopupAddDoc/PopupAddDoc';
import PopupEditDoc from '../PopupEditDoc/PopupEditDoc';
import PopupDocQR from '../PopupDocQR/PopupDocQR';
import PopupConfirmDoc from '../PopupConfirmDoc/PopupConfirmDoc';
import InfoToolTip from '../InfoToolTip/InfoToolTip';

function Docs({
  docsCards,
  isDoc,
  isUniqueLink,
  isPopupDocAdd,
  isPopupDocEdit,
  isPopupDocQR,
  isPopupConfirmDoc,
  handleCreateDoc,
  handleEditDoc,
  handleDeleteLink,
  handleOpenPopupAddDoc,
  handleClosePopupAddDoc,
  handleOpenPopupEditDoc,
  handleClosePopupEditDoc,
  handleOpenPopupDocQR,
  handleClosePopupDocQR,
  handleOpenPopupConfirmDoc,
  handleClosePopupConfirmDoc,
  checkForUniqueLink,
  checkForUniqueDoc,
  isMassageUniqueLinkErr,
  isMassageUniqueDocErr,
  isErrorDocAPI,
  isMessageErrorDocAPI,
}) {
  const [isInfoToolTip, setInfoToolTip] = useState(false);
  const [isInfoToolTipMessage, setInfoToolTipMessage] = useState('');

  function handleOpenInfoToolTip() {
    setInfoToolTip(true);
  }

  function handleCloseInfoToolTip() {
    setInfoToolTip(false);
    setInfoToolTipMessage('');
  }

  function handleInfoToolTipMessage(text) {
    setInfoToolTipMessage(text);
  }

  return (
    <section className="users users_type_doc">
      <h2 className="title">Документы</h2>

      {docsCards.length === 0
        ? <p className="users__not-found">Нет записей</p>
        : (
          <DocsTable
            docsCards={docsCards}
            handleOpenPopupEditDoc={handleOpenPopupEditDoc}
            handleOpenPopupDocQR={handleOpenPopupDocQR}
            handleDeleteLink={handleDeleteLink}
            handleOpenPopupConfirmDoc={handleOpenPopupConfirmDoc}
            handleOpenInfoToolTip={handleOpenInfoToolTip}
            handleCloseInfoToolTip={handleCloseInfoToolTip}
            handleInfoToolTipMessage={handleInfoToolTipMessage}
          />
        )}

      <button
        type="button"
        onClick={handleOpenPopupAddDoc}
        className="users__btn"
      >
        <div className="users__btn_logo users__btn_logo_docs" />
      </button>

      <PopupAddDoc
        isUniqueLink={isUniqueLink}
        isPopupDocAdd={isPopupDocAdd}
        handleClosePopupAddDoc={handleClosePopupAddDoc}
        handleCreateDoc={handleCreateDoc}
        checkForUniqueLink={checkForUniqueLink}
        checkForUniqueDoc={checkForUniqueDoc}
        isMassageUniqueLinkErr={isMassageUniqueLinkErr}
        isMassageUniqueDocErr={isMassageUniqueDocErr}
        isErrorDocAPI={isErrorDocAPI}
        isMessageErrorDocAPI={isMessageErrorDocAPI}
      />

      <PopupEditDoc
        isDoc={isDoc}
        isUniqueLink={isUniqueLink}
        isPopupDocEdit={isPopupDocEdit}
        handleEditDoc={handleEditDoc}
        handleClosePopupEditDoc={handleClosePopupEditDoc}
        checkForUniqueLink={checkForUniqueLink}
        checkForUniqueDoc={checkForUniqueDoc}
        isMassageUniqueLinkErr={isMassageUniqueLinkErr}
        isMassageUniqueDocErr={isMassageUniqueDocErr}
        isErrorDocAPI={isErrorDocAPI}
        isMessageErrorDocAPI={isMessageErrorDocAPI}
      />

      <PopupDocQR
        isDoc={isDoc}
        isPopupDocQR={isPopupDocQR}
        handleClosePopupDocQR={handleClosePopupDocQR}
      />

      <PopupConfirmDoc
        isDoc={isDoc}
        handleDeleteLink={handleDeleteLink}
        isPopupConfirmDoc={isPopupConfirmDoc}
        handleClosePopupConfirmDoc={handleClosePopupConfirmDoc}
      />

      <InfoToolTip
        isInfoToolTip={isInfoToolTip}
        isInfoToolTipMessage={isInfoToolTipMessage}
        handleCloseInfoToolTip={handleCloseInfoToolTip}
      />

    </section>
  );
}

export default Docs;