/* eslint-disable react/prop-types */
/* eslint-disable no-console */
import React from 'react';
import { useHistory } from 'react-router-dom';

import './Users.scss';

import UsersTable from '../UsersTable/UsersTable';
import PopupQR from '../PopupQR/PopupQR';
import PopupConfirm from '../PopupConfirm/PopupConfirm';

function Users({
  usersCards,
  handleDeleteCard,
  handleOpenPopupConfirm,
  handleCreateQR,
  handleGetCardByLink,
  isPopupOpenQR,
  onPopupCloseQR,
  isPopupOpenConfirm,
  onPopupCloseConfirm,
}) {
  const history = useHistory();

  function handleClickBtn() {
    history.push('/create');
  }
  return (
    <section className="users">
      <h2 className="title">Пользователи</h2>

      {usersCards.length === 0
        ? <p className="users__not-found">Нет записей</p>
        : (
          <UsersTable
            usersCards={usersCards}
            handleOpenPopupConfirm={handleOpenPopupConfirm}
            handleCreateQR={handleCreateQR}
            handleGetCardByLink={handleGetCardByLink}
          />
        )}

      <button
        type="button"
        onClick={handleClickBtn}
        className="users__btn"
      >
        <div className="users__btn_logo users__btn_logo_user-add" />
      </button>

      <PopupQR
        isPopupOpenQR={isPopupOpenQR}
        onPopupCloseQR={onPopupCloseQR}
      />

      <PopupConfirm
        handleDeleteCard={handleDeleteCard}
        isPopupOpenConfirm={isPopupOpenConfirm}
        onPopupCloseConfirm={onPopupCloseConfirm}
      />

    </section>
  );
}

export default Users;