/* eslint-disable react/prop-types */
import React from 'react';

import './UsersTable.scss';

import UsersList from '../UsersList/UsersList';

function UsersTable({
  usersCards,
  handleOpenPopupConfirm,
  handleCreateQR,
  handleGetCardByLink,
}) {
  return (
    <table className="users-table">
      <thead className="users-table__head">
        <tr className="users-table__tr users-table__tr_type_head">
          <th className="users-table__th-head">Ссылка</th>
          <th className="users-table__th-head">Фамилия</th>
          <th className="users-table__th-head">Имя</th>
          <th className="users-table__th-head">Отчество</th>
          <th className="users-table__th-head">Название организации</th>
          <th className="users-table__th-head">Должность</th>
          <th className="users-table__th-head">QR-код</th>
          <th className="users-table__th-head">Действия</th>
        </tr>
      </thead>

      <tbody className="users-table__body">
        <UsersList
          usersCards={usersCards}
          // handleDeleteCard={handleDeleteCard}
          handleOpenPopupConfirm={handleOpenPopupConfirm}
          handleCreateQR={handleCreateQR}
          handleGetCardByLink={handleGetCardByLink}
        />
      </tbody>
    </table>
  );
}

export default UsersTable;

/*
          <th className="users-table__th-head">Статус</th>
*/