/* eslint-disable prefer-destructuring */
import { useState, useCallback } from 'react';

// хук управления формой и валидации формы
export default function useValidForm() {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [isValidForm, setValidForm] = useState(false);

  const handleChange = (evt) => {
    const target = evt.target;
    const { name } = target;
    const { value } = target;

    setValues({ ...values, [name]: value });
    // работает при создании карточки, когда проверяются инпуты,
    // но не работает при редактировании, т.к. не все поля меняются
    setValues({ ...values, [name]: (value === null || value === undefined || value === 0) ? '' : value });
    setErrors({ ...errors, [name]: target.validationMessage });
    setValidForm(target.closest('form').checkValidity());
  };

  const resetForm = useCallback(
    (newValues = {}, newErrors = {}, newValidForm = false) => {
      setValues(newValues);
      setErrors(newErrors);
      setValidForm(newValidForm);
    },
    [setValues, setErrors, setValidForm],
  );

  return {
    values,
    setValues,
    errors,
    isValidForm,
    handleChange,
    resetForm,
  };
}