import * as constants from '../constants';

const { BASE_URL, headers, fixPromise } = constants;

// запрос всех карточек
export const getCards = () => fetch(`${BASE_URL}/cards`, {
  method: 'GET',
  headers,
}).then((res) => fixPromise(res));

// запрос карточки по короткой ссылке
export const getCardByLink = (link) => fetch(`${BASE_URL}/cards/${link}`, {
  method: 'GET',
  headers,
}).then((res) => fixPromise(res));

// создание карточки
export const createCard = (data) => fetch(`${BASE_URL}/cards`, {
  method: 'POST',
  body: data,
}).then((res) => fixPromise(res));

// редактирование карточки
export const editCard = (data, cardId) => fetch(`${BASE_URL}/cards/${cardId}`, {
  method: 'POST',
  body: data,
}).then((res) => fixPromise(res));

// удаление карточки
export const deleteCard = (cardId) => fetch(`${BASE_URL}/cards/${cardId}`, {
  method: 'DELETE',
  headers,
}).then((res) => fixPromise(res));

// удаление карточки
export const sendVCard = ({
  email, vcf, name, surname, urlShort,
}) => fetch(`${BASE_URL}/cards/sendvcard`, {
  method: 'POST',
  headers,
  body: JSON.stringify({
    email, vcf, name, surname, urlShort,
  }),
}).then((res) => fixPromise(res));
