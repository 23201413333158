/* eslint-disable react/prop-types */
import React from 'react';

import DocCard from '../DocCard/DocCard';

function DocsList({
  docsCards,
  handleOpenPopupEditDoc,
  handleOpenPopupDocQR,
  handleOpenPopupConfirmDoc,
  handleOpenInfoToolTip,
  handleCloseInfoToolTip,
  handleInfoToolTipMessage,
}) {
  return (
    <>
      {docsCards.map((doc) => (
        <DocCard
          doc={doc}
          key={doc._id}
          handleOpenPopupEditDoc={handleOpenPopupEditDoc}
          handleOpenPopupDocQR={handleOpenPopupDocQR}
          handleOpenPopupConfirmDoc={handleOpenPopupConfirmDoc}
          handleOpenInfoToolTip={handleOpenInfoToolTip}
          handleCloseInfoToolTip={handleCloseInfoToolTip}
          handleInfoToolTipMessage={handleInfoToolTipMessage}
        />
      ))}
    </>
  );
}

export default DocsList;
