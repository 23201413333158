/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React from 'react';

function InfoToolTip({
  isInfoToolTip,
  isInfoToolTipMessage,
  handleCloseInfoToolTip,
}) {
  return (
    <div
      // className="popup popup_opened popup"
      className={isInfoToolTip ? 'popup popup_opened' : 'popup'}
      onClick={handleCloseInfoToolTip}
    >
      <div
        // className="popup__container popup__container_opened popup__container"
        className={isInfoToolTip ? 'popup__container popup__container_opened' : 'popup__container'}
        onClick={(evt) => evt.stopPropagation()}
      >
        <div className="popup__wrapper">

          <span span className="popup__subtitle">
            {isInfoToolTipMessage}
          </span>

        </div>

      </div>
    </div>
  );
}

export default InfoToolTip;
