import * as constants from '../constants';

const { BASE_URL, headers, fixPromise } = constants;

// получить все документы
export const getLinks = () => fetch(`${BASE_URL}/links`, {
  method: 'GET',
  headers,
}).then((res) => fixPromise(res));

// получить документ по id
export const getLink = (id) => fetch(`${BASE_URL}/links/${id}`, {
  method: 'GET',
  headers,
}).then((res) => fixPromise(res));

// получить документ по ссылке на документ
export const getLinkByUrl = (link) => fetch(`${BASE_URL}/links/url`, {
  method: 'POST',
  headers,
  body: JSON.stringify({
    longUrl: link,
  }),
}).then((res) => fixPromise(res));

// запрос для редиректа
export const getRedirect = (id) => fetch(`${BASE_URL}/${id}`, {
  method: 'GET',
  headers,
})
  .then((res) => fixPromise(res));

// создать документ
export const createDoc = ({
  name, longUrl, idLink, shortUrl,
}) => fetch(`${BASE_URL}/links`, {
  method: 'POST',
  headers,
  body: JSON.stringify({
    name, longUrl, shortUrl, id: idLink,
  }),
})
  .then((res) => fixPromise(res));

// редактировать документ
export const editDoc = ({
  _id, name, longUrl, idLink, shortUrl,
}) => fetch(`${BASE_URL}/links`, {
  method: 'PATCH',
  headers,
  body: JSON.stringify({
    _id, name, longUrl, shortUrl, id: idLink,
  }),
}).then((res) => fixPromise(res));

// удаление карточки
export const deleteDoc = (docId) => fetch(`${BASE_URL}/links/${docId}`, {
  method: 'DELETE',
  headers,
}).then((res) => fixPromise(res));
