/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React from 'react';

import '../Popup/Popup.scss';

function PopupDocQR({
  isDoc,
  isPopupDocQR,
  handleClosePopupDocQR,
}) {
  return (
    <div
      className={isPopupDocQR ? 'popup popup_opened' : 'popup'}
      onClick={handleClosePopupDocQR}
    >
      <div
        className={isPopupDocQR
          ? 'popup__container popup__container_type_QR popup__container_opened'
          : 'popup__container popup__container_type_QR'}
        onClick={(evt) => evt.stopPropagation()}
      >
        <h3 className="popup__title popup__title_type_qr-doc">{isDoc.name}</h3>
        <canvas id="qr" className="popup__qr" />
        <button
          type="button"
          aria-label="Закрыть"
          onClick={handleClosePopupDocQR}
          className="popup__button-close"
        />
      </div>
    </div>
  );
}

export default PopupDocQR;