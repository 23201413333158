/* eslint-disable react/prop-types */
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import './Header.scss';

function Header({
  loggedIn,
  userEmail,
  signOut,
}) {
  const location = useLocation();
  const path = location.pathname;

  return (
    <header className="header">
      <Link
        to="/"
        rel="noreferrer"
        className="header__logo"
      />

      {loggedIn ? (
        <nav className="header__nav">
          <ul className="header__nav-wrapper">

            <li className="header__nav-item">
              <Link
                to="/"
                // className="header__link header__text header__nav-link"
                className={`header__text
                ${path === '/' ? 'header__nav-link' : 'header__link'}
                `}
              >
                Пользователи
              </Link>
            </li>

            <li className="header__nav-item">
              <Link
                to="/docs"
                // className="header__link header__text header__nav-link"
                className={`header__text
                ${path === '/docs' ? 'header__nav-link' : 'header__link'}
                `}
              >
                Документы
              </Link>
            </li>
          </ul>

          <div className="header__auth">
            <p
              className="header__email header__text"
            >
              {userEmail}
            </p>

            <Link
              to="/signin"
              onClick={signOut}
              className="header__sign header__text"
            >
              Выйти
            </Link>
          </div>
        </nav>
      ) : (
        <></>
      )}
    </header>
  );
}

export default Header;

/*
<div
        className="header__auth"
      >
        {loggedIn ? (
          <p
            className="header__email header__text"
          >
            {userEmail}
          </p>
        ) : (
          <>
            {
              регистрация пользователя
              скрыть, т.к. она не нужна
              <Link
                to="/signup"
                className="header__link header__text"
              >
                Регистрация
              </Link>
            }
            </>
          )}

          {loggedIn ? (
            <Link
              to="/signin"
              onClick={signOut}
              className="header__sign header__text"
            >
              Выйти
            </Link>
          ) : (
            <>
            </>
          )}
        </div>
*/