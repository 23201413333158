/* eslint-disable react/prop-types */
import React from 'react';

import FormUsers from '../FormUsers/FormUsers';

function CreateCard({
  onSubmit,
  handleCreateCard,
  sendingData,
  messageSendingData,
  isErrorAPI,
  isMessageErrorAPI,
  isErrLink,
  isMessageErrLink,
  isDisabledInput,
}) {
  return (
    <FormUsers
      handleCreateCard={handleCreateCard}
      onSubmit={onSubmit}
      title="Создание карточки"
      buttonText={sendingData ? messageSendingData : 'Создать'}
      isErrorAPI={isErrorAPI}
      isMessageErrorAPI={isMessageErrorAPI}
      isErrLink={isErrLink}
      isMessageErrLink={isMessageErrLink}
      isDisabledInput={isDisabledInput}
    />
  );
}

export default CreateCard;