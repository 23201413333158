/* eslint-disable react/prop-types */
import React from 'react';

import UserCard from '../UserCard/UserCard';

function UsersList({
  usersCards,
  handleOpenPopupConfirm,
  handleCreateQR,
  handleGetCardByLink,
}) {
  return (
    <>
      {usersCards.map((card) => (
        <UserCard
          card={card}
          key={card._id}
          handleOpenPopupConfirm={handleOpenPopupConfirm}
          handleCreateQR={handleCreateQR}
          handleGetCardByLink={handleGetCardByLink}
        />
      ))}
    </>
  );
}

export default UsersList;
