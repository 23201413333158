/* eslint-disable array-callback-return */
/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import {
  Route, Switch, Redirect, useHistory, useLocation,
} from 'react-router-dom';

import './App.scss';

import QRious from 'qrious';

import ProtectedRoute from '../ProtectedRoute/ProtectedRoute';

import Header from '../Header/Header';
// регистрация пользователя
// скрыть, т.к. она не нужна
// import Register from '../Register/Register';
import Login from '../Login/Login';
import Users from '../Users/Users';
import Docs from '../Docs/Docs';
import CreateCard from '../CreateCard/CreateCard';
import EditCard from '../EditCard/EditCard';
import Card from '../Card/Card';
import NotFound from '../NotFound/NotFound';

import * as MainApi from '../../utils/Api/MainApi';
import * as CardApi from '../../utils/Api/CardApi';
import * as DocApi from '../../utils/Api/DocApi';

import { version } from '../../utils/constants';

// скрыть регистрацию пользователя
const {
  // register,
  login,
  getUserInfo,
} = MainApi;

const {
  getCards,
  getCardByLink,
  createCard,
  editCard,
  deleteCard,
  sendVCard,
} = CardApi;

const {
  getLinks,
  getLink,
  getLinkByUrl,
  // getRedirect,
  createDoc,
  editDoc,
  deleteDoc,
} = DocApi;

function App() {
  function authenticate() {
    return new Promise((resolve) => setTimeout(resolve, 0));
  }

  authenticate().then((res) => {
    const element = document.getElementById('ipl-progress-indicator');

    if (element) {
      // fade out
      element.classList.remove('available_disabled');
      element.classList.add('available');
      setTimeout(() => {
        element.remove();
      }, 1500);
    }

    return res;
  }).catch((err) => console.log(err));

  const history = useHistory();
  const location = useLocation();
  const path = location.pathname;

  // стейт залогиненного пользователя
  const [loggedIn, setLoggedIn] = useState(false);
  // стейт email пользователя
  const [userEmail, setUserEmail] = useState('');
  // стейт массива карточек
  const [isCardsArr, setCardsArr] = useState([]);
  // стейт массива документов
  const [isDocsArr, setDocsArr] = useState([]);
  // стейт документа
  const [isDoc, setDoc] = useState({});

  // стейт открытия popup для QR
  const [isPopupQR, setPopupQR] = useState(false);
  // стейт открытия popup для Confirm
  const [isPopupConfirm, setPopupConfirm] = useState(false);
  // стейт открытия popup для Doc Add
  const [isPopupDocAdd, setPopupDocAdd] = useState(false);
  // стейт открытия popup для Doc Edit
  const [isPopupDocEdit, setPopupDocEdit] = useState(false);
  // стейт открытия popup для QR-Doc
  const [isPopupDocQR, setPopupDocQR] = useState(false);
  // стейт открытия popup для Confirm Doc
  const [isPopupConfirmDoc, setPopupConfirmDoc] = useState(false);

  // стейт состояния отправки данных на сервер
  const [sendingData, setSendingData] = useState(false);
  // стейт сообщений отправки данных на сервер
  const [messageSendingData, setMessageSendingData] = useState('');
  // стейт состояния сообщения об ошибке API
  const [isErrorAPI, setErrorAPI] = useState(false);
  // стейт сообщения об ошибке API
  const [isMessageErrorAPI, setMessageErrorAPI] = useState('');
  // стейт активнивного/неактивного инпута
  const [isDisabledInput, setDisabledInput] = useState(false);
  // стейт состояния ошибки об ошибке API DOC
  const [isErrorDocAPI, setErrorDocAPI] = useState(false);
  // стейт сообщения об ошибке API DOC
  const [isMessageErrorDocAPI, setMessageErrorDocAPI] = useState('');
  // стейт короткой ссылки документа
  // const [isCheckDoc, setCheckDoc] = useState(false);
  const [isShortDocLink, setShortDocLink] = useState({});

  // достать короткую из localStorage
  const cardLink = localStorage.getItem('cardLink');

  function RedirectPageForLinkDoc(link) {
    if (link) {
      const a = document.createElement('a');
      a.href = `${link}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }

    history.push('/polati');
  }

  function checkDocPage(link) {
    getLink(link)
      .then((res) => {
        if (res) {
          setShortDocLink(res);
          RedirectPageForLinkDoc(res.longUrl);
        }

        return res;
      })
      .catch((err) => {
        console.log(err);
        // history.push('/polati');
      });
  }

  function handleOpenPopupAddDoc() {
    setPopupDocAdd(true);

    setMassageUniqueLinkErr('');
    setMessageErrorDocAPI('');
    setMassageUniqueDocErr('');
  }

  function handleClosePopupAddDoc() {
    setPopupDocAdd(false);

    setMassageUniqueLinkErr('');
    setMessageErrorDocAPI('');
    setMassageUniqueDocErr('');
    setErrorDocAPI(false);
  }

  function handleOpenPopupEditDoc(doc) {
    getLink(doc.id)
      .then((res) => {
        setDoc(res);
        setPopupDocEdit(true);

        setMassageUniqueLinkErr('');
        setMessageErrorDocAPI('');
        setMassageUniqueDocErr('');
        setErrorDocAPI(false);

        return res;
      })
      .catch((err) => console.log(err));
  }

  // стейт уникальности короткой ссылки документа
  const [isUniqueLink, setUniqueLink] = useState(false);
  // стейт сообщения уникальности короткой ссылки документа
  const [isMassageUniqueLinkErr, setMassageUniqueLinkErr] = useState('');

  // стейт уникальности короткой ссылки документа
  // const [isUniqueDoc, setUniqueDoc] = useState(false);
  // стейт сообщения уникальности короткой ссылки документа
  const [isMassageUniqueDocErr, setMassageUniqueDocErr] = useState('');

  // проверить короткую ссылку документа на уникальность
  function checkForUniqueLink(link) {
    getLink(link)
      .then((res) => {
        console.log(`Ссылка ${res.shortUrl} уже существует`);
        setUniqueLink(false);
        setMassageUniqueLinkErr(`Ссылка ${res.shortUrl} уже существует`);

        return res;
      })
      .catch(() => {
        setUniqueLink(true);
        // setMassageUniqueLinkErr('');
      });
  }

  function checkForUniqueDoc(link) {
    getLinkByUrl(link)
      .then((res) => {
        console.log(`Документ ${res.longUrl} уже существует`);
        setMassageUniqueDocErr('Документ уже существует');

        return res;
      })
      .catch(() => {
        setMassageUniqueDocErr('');
      });
  }

  function handleClosePopupEditDoc() {
    setPopupDocEdit(false);

    setMassageUniqueLinkErr('');
    setMessageErrorDocAPI('');
    setMassageUniqueDocErr('');
    setErrorDocAPI(false);
  }

  function handleOpenPopupDocQR(doc) {
    getLink(doc.id)
      .then((res) => {
        setDoc(res);
        const canvas = document.getElementById('qr');

        const createQR = () => new QRious({
          element: canvas,
          value: res.shortUrl,
          size: 300,
          backgroundAlpha: 0,
          foreground: 'black',
        });

        createQR();

        setPopupDocQR(true);

        return res;
      })
      .catch((err) => console.log(err));
  }

  function handleClosePopupDocQR() {
    setPopupDocQR(false);
  }

  // функция открытия PopupConfirm
  function handleOpenPopupConfirm() {
    setPopupConfirm(true);
  }

  // функция закрытия PopupConfirm
  function handleClosePopupConfirm() {
    setPopupConfirm(false);
  }

  // функция закрытия PopupQR
  function handleClosePopupQR() {
    setPopupQR(false);
    // удалить данные из localStorage
    localStorage.removeItem('linkForQR');
    localStorage.removeItem('nameForQR');
    localStorage.removeItem('roleForQR');
  }

  // const qr = new QRious();
  // функция создания QR-кода
  function handleCreateQR() {
    setPopupQR(true);

    const canvas = document.getElementById('qr');
    const linkForQR = localStorage.getItem('linkForQR');

    const createQR = () => new QRious({
      element: canvas,
      value: linkForQR,
      size: 300,
      backgroundAlpha: 0,
      foreground: 'black',
    });

    createQR();
  }

  // регистрация пользователя
  // скрыть, т.к. она не нужна
  /*
  function handleRegister(email, password) {
    return register({ email, password })
      .then(() => {
        console.log('Регистрация прошла успешно!');
        // авторизировать пользователя
        // handleLogin(email, password);
      })
      .catch((err) => console.log(`Не удалось зарегистрироваться. ${err}`));
  }
  */

  // авторизация пользователя
  function handleLogin(email, password) {
    setErrorAPI(false);
    setSendingData(true);
    setMessageSendingData('Cохранение...');
    // задизейблить инпуты
    setDisabledInput(true);
    login({ email, password })
      .then((res) => {
        setErrorAPI(false);
        // положить токен в localStorage
        localStorage.setItem('token', res.token);
        // считать пользователя залогиненным
        setLoggedIn(true);
        setSendingData(false);
        console.log('Авторизация прошла успешно!');
        // переадресовать на главную страницу
        history.push('/');
        // вкл инпуты
        setDisabledInput(false);
        // правило promise/always-return
        return res;
      })
      .catch((err) => {
        console.log(`Не удалось войти. ${err}`);
        // вкл инпуты
        setDisabledInput(false);
        setSendingData(false);
        setErrorAPI(true);
        setMessageErrorAPI('Не удалось войти. Попробуйте еще раз');
      });
  }

  // выход пользователя
  function handleSignOut() {
    // удалить токен из localStorage
    localStorage.removeItem('token');
    // НЕ считать пользователя залогиненным
    setLoggedIn(false);
  }

  // стейт проверки короткой ссылки
  const [isShortLink, setShortLink] = useState(false);
  // стейт ошибки, если такая ссылка уже есть
  const [isErrLink, setErrLink] = useState(false);
  // стейт сообщения об ошибке если такая ссылка есть
  const [isMessageErrLink, setMessageErrLink] = useState('');

  // стейт ссылки, из БД
  // const [isLinkFromDB, setLinkFromDB] = useState('');

  function handleShortLink(link) {
    setErrLink(false);
    setMessageErrLink('');

    // переключить стейт стейт
    setShortLink(true);

    if (link) {
      getCardByLink(link)
        .then((res) => {
          // зафиксировать что такая ссылка есть
          setShortLink(true);

          setSendingData(false);
          setErrorAPI(true);
          setMessageErrorAPI('Не удалось создать карточку. Попробуйте еще раз');

          setErrLink(true);
          setMessageErrLink('Ссылка занята. Оставьте поле пустым, либо выберете другое значение');

          // вкл инпуты
          setDisabledInput(false);

          // записать полученную ссылку в стейт
          // setLinkFromDB(res.link);

          return res.link;
        })
        .catch((err) => {
          setErrorAPI(false);
          // зафиксировать что такой ссылки нет
          setShortLink(false);

          setSendingData(false);

          setErrLink(false);

          // вкл инпуты
          setDisabledInput(false);
          console.log(err);
        });
      /*
      .finally(() => {
        setSendingData(false);
        // вкл инпуты
        setDisabledInput(false);
      });
      */

      /*
      if (link === isLinkFromDB) {
        // зафиксировать что такая ссылка есть
        setShortLink(true);

        // зафиксировать ошибку, что такая ссылка есть
        setErrLink(true);
      } else {
        // зафиксировать что такой ссылки нет
        setShortLink(false);

        // убрать ошибку, что такой ссылки нет
        setErrLink(false);
      }
      */
    }
  }

  // ! доделать
  // создать карточку
  function handleCreateCard(data) {
    setErrorAPI(false);
    setSendingData(true);
    setMessageSendingData('Cохранение...');
    // задизейблить инпуты
    setDisabledInput(true);

    // если в форме есть ссылка
    if (data.link) {
      // проверить есть ли такая в БД
      handleShortLink(data.link);
    }

    // console.log(isShortLink);

    // создать карточку
    createCard(data)
      .then((res) => {
        setErrorAPI(false);
        handleGetCards();
        history.push('/');

        setSendingData(false);
        // вкл инпуты
        setDisabledInput(false);
        console.log('Создание карточки прошло успешно');
        return res;
      })
      .catch((err) => {
        setErrorAPI(true);
        setSendingData(false);
        // вкл инпуты
        setDisabledInput(false);
        setMessageErrorAPI('Не удалось создать карточку. Попробуйте еще раз');
        console.log(`Не удалось создать карточку. ${err}`);
      });
  }

  // отправить vcard на почту
  function handleSendVCardToEmail(data) {
    console.log(data);
    return sendVCard(data)
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  }

  // редактировать карточку
  function handleEditCard(data, cardId) {
    setErrorAPI(false);
    setSendingData(true);
    setMessageSendingData('Cохранение...');
    // задизейблить инпуты
    setDisabledInput(true);

    // setShortLink(true);

    // если в форме есть ссылка
    if (data.link) {
      // и если ссылка изменена
      if (cardLink !== data.link) {
        // проверить есть ли такая в БД
        handleShortLink(data.link);
      }
    }

    // если короткая ссылка не занята
    if (!isShortLink) {
      editCard(data, cardId)
        .then((res) => {
          setErrorAPI(false);
          handleGetCards();
          history.push('/');

          setSendingData(false);
          // вкл инпуты
          setDisabledInput(false);
          console.log('Редактирование карточки прошло успешно');
          return res;
        })
        .catch((err) => {
          setErrorAPI(true);
          setSendingData(false);
          // вкл инпуты
          setDisabledInput(false);
          setMessageErrorAPI('Не удалось редактировать карточку. Попробуйте еще раз');
          console.log(`Не удалось редактировать карточку. ${err}`);
        });
    }
  }

  // удалить карточки
  function handleDeleteCard() {
    const cardId = localStorage.getItem('cardId');

    return deleteCard(cardId)
      .then((res) => {
        console.log('Удаление карточки прошло успешно');
        handleGetCards();
        // правило promise/always-return
        return res;
      })
      .catch((err) => console.log(`Не удалось удалить карточку. ${err}`))
      .finally(() => setPopupConfirm(false));
  }

  // получить массив карточек с сервера
  function handleGetCards() {
    // setLoading(true);
    return getCards()
      .then((cards) => {
        // записать карточки в стейт
        setCardsArr(cards);
        // правило promise/always-return
        return cards;
      })
      .catch((err) => {
        console.log(`Не удалось получить данные с сервера. ${err}`);
      });
  }

  const [isCardInfo, setCardInfo] = useState({});

  // получить данные карточки по короткой ссылке
  function handleGetCardByLink(link) {
    // setLoading(true);
    return getCardByLink(link)
      .then((res) => {
        console.log('Данные карточки получены');
        // положить в localStorage ccылку карточки
        localStorage.setItem('cardLink', res.link);
        setCardInfo(res);

        // history.push(`/${link}`);
        // handleLoading();
        // правило promise/always-return
        return res;
      })
      .catch((err) => {
        // history.push('/polati');
        console.log(`Не удалось получить данные с сервера. ${err}`);
      });
  }

  function handleGetLinks() {
    return getLinks()
      .then((links) => {
        setDocsArr(links);

        return links;
      })
      .catch((err) => {
        console.log(`Не удалось получить данные с сервера. ${err}`);
      });
  }

  function handleCreateDoc(name, longUrl, idLink, shortUrl) {
    setMessageErrorDocAPI('');
    setErrorDocAPI(false);

    console.log({
      name, longUrl, idLink, shortUrl,
    });

    createDoc({
      name, longUrl, idLink, shortUrl,
    })
      .then((res) => {
        handleGetLinks();
        handleClosePopupAddDoc();

        setMassageUniqueLinkErr('');
        setMessageErrorDocAPI('');
        setErrorDocAPI(false);

        return res;
      })
      .catch((err) => {
        console.log(err);
        setErrorDocAPI(true);
        setMessageErrorDocAPI('Что-то пошло не так. Попробуйте ещё раз');
      });
  }

  function handleEditDoc(
    _id, name, longUrl, shortUrl, idLink,
  ) {
    setMessageErrorDocAPI('');
    setErrorDocAPI(false);

    editDoc({
      _id, name, longUrl, shortUrl, idLink,
    })
      .then((res) => {
        handleGetLinks();
        handleClosePopupEditDoc();

        setMassageUniqueLinkErr('');
        setMessageErrorDocAPI('');
        setErrorDocAPI(false);

        return res;
      })
      .catch((err) => {
        console.log(err);
        setErrorDocAPI(true);
        setMessageErrorDocAPI('Что-то пошло не так. Попробуйте ещё раз');
      });
  }

  function handleOpenPopupConfirmDoc(doc) {
    setDoc(doc);
    setPopupConfirmDoc(true);
  }

  function handleClosePopupConfirmDoc() {
    setPopupConfirmDoc(false);
  }

  function handleDeleteLink(docId) {
    return deleteDoc(docId)
      .then((res) => {
        console.log('Удаление карточки прошло успешно');
        handleGetLinks();

        // правило promise/always-return
        return res;
      })
      .catch((err) => console.log(`Не удалось удалить карточку. ${err}`))
      .finally(() => handleClosePopupConfirmDoc());
  }

  const [isHeader, setHeader] = useState(true);
  // const [isPath, setPath] = useState(false);

  useEffect(() => {
    // достать токен из localStorage
    console.log(`Версия: ${version}`);
    const token = localStorage.getItem('token');

    // вытащить ссылку из местонахождения
    if (path !== '/signin'
      && path !== '/'
      && path !== '/docs'
      && path !== '/create'
      && path !== '/edit'
      && path !== `/${cardLink}`) {
      const shortLinkDoc = path.substr(1);

      checkDocPage(shortLinkDoc);
    }

    if (path) {
      setMessageSendingData('');
      setMessageErrorAPI('');
      setMessageErrLink('');
      setMassageUniqueLinkErr('');
    }

    // если есть токен
    if (token) {
      // считать пользователя залогиненным
      setLoggedIn(true);
      // чтоб при перезагрузке стр отправляло на ту же стр,
      // а не на главную
      history.push(path);
    }

    // если пользователь залогинен
    if (loggedIn) {
      // обновить стейт карточек
      handleGetCards();
      // обновить стейт документов
      handleGetLinks();
      // сделать запрос информации пользователя
      getUserInfo(token)
        .then((res) => {
          // записать email пользователя в стейт
          setUserEmail(res.email);
          // правило promise/always-return
          return res;
        })
        .catch((err) => console.log(`Не удалось передать токен. ${err}`));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path, loggedIn, history, cardLink]);

  useEffect(() => {
    if (path === `/${cardLink}`) {
      setHeader(false);
    } else {
      setHeader(true);
    }
  }, [cardLink, path]);

  return (
    <>
      <div className={isHeader ? 'page' : 'page__card'}>
        {isHeader
          ? (
            <Header
              loggedIn={loggedIn}
              userEmail={userEmail}
              signOut={handleSignOut}
            />
          ) : (
            <>
            </>
          )}

        <main
          className="content"
        >
          <Switch>

            {
              // регистрация пользователя
              // скрыть, т.к. она не нужна
              /*
              <Route
                path="/signup"
              >
                <Register
                  onRegister={handleRegister}
                />
              </Route>
              */
            }

            <Route
              path="/signin"
            >
              {loggedIn ? (
                <Redirect to="/" />
              ) : (
                <Login
                  onLogin={handleLogin}
                  sendingData={sendingData}
                  messageSendingData={messageSendingData}
                  isErrorAPI={isErrorAPI}
                  isMessageErrorAPI={isMessageErrorAPI}
                  isDisabledInput={isDisabledInput}
                />
              )}
            </Route>

            <ProtectedRoute
              exact
              path="/"
              component={Users}
              loggedIn={loggedIn}
              usersCards={isCardsArr}
              handleDeleteCard={handleDeleteCard}
              handleOpenPopupConfirm={handleOpenPopupConfirm}
              handleCreateQR={handleCreateQR}
              handleGetCardByLink={handleGetCardByLink}
              isPopupOpenQR={isPopupQR}
              isPopupOpenConfirm={isPopupConfirm}
              onPopupCloseQR={handleClosePopupQR}
              onPopupCloseConfirm={handleClosePopupConfirm}
            />

            <ProtectedRoute
              exact
              path="/docs"
              component={Docs}
              loggedIn={loggedIn}
              docsCards={isDocsArr}
              isDoc={isDoc}
              isUniqueLink={isUniqueLink}
              isPopupDocAdd={isPopupDocAdd}
              isPopupDocEdit={isPopupDocEdit}
              isPopupDocQR={isPopupDocQR}
              isPopupConfirmDoc={isPopupConfirmDoc}
              handleCreateDoc={handleCreateDoc}
              handleEditDoc={handleEditDoc}
              handleDeleteLink={handleDeleteLink}
              handleOpenPopupAddDoc={handleOpenPopupAddDoc}
              handleClosePopupAddDoc={handleClosePopupAddDoc}
              handleOpenPopupEditDoc={handleOpenPopupEditDoc}
              handleClosePopupEditDoc={handleClosePopupEditDoc}
              handleOpenPopupDocQR={handleOpenPopupDocQR}
              handleClosePopupDocQR={handleClosePopupDocQR}
              handleOpenPopupConfirmDoc={handleOpenPopupConfirmDoc}
              handleClosePopupConfirmDoc={handleClosePopupConfirmDoc}
              checkForUniqueLink={checkForUniqueLink}
              checkForUniqueDoc={checkForUniqueDoc}
              isMassageUniqueLinkErr={isMassageUniqueLinkErr}
              isMassageUniqueDocErr={isMassageUniqueDocErr}
              isErrorDocAPI={isErrorDocAPI}
              isMessageErrorDocAPI={isMessageErrorDocAPI}
            />

            <ProtectedRoute
              exact
              path="/create"
              component={CreateCard}
              loggedIn={loggedIn}
              handleCreateCard={handleCreateCard}
              sendingData={sendingData}
              messageSendingData={messageSendingData}
              isErrorAPI={isErrorAPI}
              isMessageErrorAPI={isMessageErrorAPI}
              isErrLink={isErrLink}
              isMessageErrLink={isMessageErrLink}
              isDisabledInput={isDisabledInput}
            />

            <ProtectedRoute
              exact
              path="/edit"
              component={EditCard}
              loggedIn={loggedIn}
              handleEditCard={handleEditCard}
              sendingData={sendingData}
              messageSendingData={messageSendingData}
              isErrorAPI={isErrorAPI}
              isMessageErrorAPI={isMessageErrorAPI}
              isErrLink={isErrLink}
              isMessageErrLink={isMessageErrLink}
              isDisabledInput={isDisabledInput}
            />

            <Route path="/polati">
              <NotFound />
            </Route>

            <Route
              path={`/${isShortDocLink.shortUrl}`}
            />

            <Route>
              <Card
                loggedIn={loggedIn}
                card={isCardInfo}
                handleGetCardByLink={handleGetCardByLink}
                handleSendVCardToEmail={handleSendVCardToEmail}
              />
            </Route>

          </Switch>

        </main>

      </div>
    </>
  );
}

export default App;
