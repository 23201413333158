/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import './Popup.scss';

// хук управления формой и валидации формы
import useValidForm from '../../hooks/useValidForm';

import { PLTDO_URL, BASE_URL } from '../../utils/constants';

function Popup({
  isPopupOpen,
  onClosePopup,
  isPageFormPopup,
  handlePageFormPopupOpen,
  handlePageFormPopupClose,
  handleSendVCardToEmail,
  card,
}) {
  const {
    values, errors, handleChange, /* isValidForm, */ resetForm,
  } = useValidForm();

  // деструктурировать card
  const {
    surname,
    name,
    vcf,
    link,
  } = card;

  function download() {
    const a = document.createElement('a');
    a.href = `${BASE_URL}/${vcf}`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  function handleClickBtnSavePhone() {
    download();

    onClosePopup();
  }

  function handleClickBtnSendEmail() {
    handlePageFormPopupOpen();
  }

  function handleClickBack() {
    handlePageFormPopupClose();
  }

  function handleSubmit(evt) {
    evt.preventDefault();

    // console.log(values.email);
    const { email } = values;
    const urlShort = `${PLTDO_URL}/${link}`;

    handleSendVCardToEmail({
      email,
      vcf,
      name,
      surname,
      urlShort,
    });

    handlePageFormPopupClose();
    onClosePopup();

    resetForm();
  }

  // потом задизейблить форму, если она не валидна
  // console.log(`Стутус валидности формы: ${isValidForm}`);

  useEffect(() => {
    resetForm();
  }, [resetForm]);

  return (
    <div
      className={isPopupOpen ? 'popup popup_opened' : 'popup'}
      onClick={onClosePopup}
    >
      <div
        className={isPopupOpen ? 'popup__container popup__container_opened' : 'popup__container'}
        onClick={(evt) => evt.stopPropagation()}
      >
        <div className="popup__wrapper">
          {isPageFormPopup
            ? (
              <Link
                to="#"
                onClick={handleClickBack}
                className="popup__link-back"
              >
                Назад
              </Link>
            ) : (
              <>
              </>
            )}
          <h3 className="popup__title">
            {!isPageFormPopup
              ? 'Сохранение контактных данных'
              : 'Отправить на почту'}
          </h3>
          <span span className="popup__subtitle">
            {!isPageFormPopup
              ? 'Как хотите сохранить данные?'
              : 'Укажите адрес электронной почты'}
          </span>

          {!isPageFormPopup
            ? (
              <>
                <button
                  type="button"
                  aria-label="Сохранить на телефон"
                  onClick={handleClickBtnSavePhone}
                  className="popup__button-send popup__button-send_type_tel"
                >
                  Сохранить
                </button>

                <button
                  type="button"
                  aria-label="Отправить на почту"
                  onClick={handleClickBtnSendEmail}
                  className="popup__button-send popup__button-send_type_email"
                >
                  Отправить на почту
                </button>
              </>
            ) : (
              <>
                <form
                  // encType="multipart/form-data"
                  // method="post"
                  className="popup__form"
                  onSubmit={handleSubmit}
                >
                  <label
                    htmlFor="popup-form-input-email"
                    className="popup__form-label"
                  >
                    <input
                      type="email"
                      name="email"
                      id="popup-form-input-email"
                      placeholder="E-mail"
                      minLength="5"
                      value={values.email || ''}
                      onChange={handleChange}
                      className="popup__form-input"
                      required
                    />
                    <span
                      className="popup__input-err"
                    >
                      {errors.email}
                    </span>
                  </label>

                  <button
                    type="submit"
                    aria-label="Сохранить на телефон"
                    // onClick={handleClickBtnSavePhone}
                    className="popup__button-send"
                  >
                    Отправить
                  </button>
                </form>
              </>
            )}

        </div>

        <button
          type="button"
          aria-label="Закрыть"
          onClick={onClosePopup}
          className="popup__button-close"
        />
      </div>
    </div>
  );
}

export default Popup;