/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
/* eslint-disable no-console */
import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import { PLTDO_URL } from '../../utils/constants';

function UserCard({
  card,
  handleOpenPopupConfirm,
  handleCreateQR,
  handleGetCardByLink,
}) {
  const history = useHistory();

  /* ! временно здесь, потом вынести */

  // обработчик кнопки редактирования карточки
  function handleClickBtnEdit() {
    // поместить инфу карточки в localStorage
    localStorage.setItem('card', JSON.stringify(card));
    localStorage.setItem('cardLink', card.link);
    // перейти на страницу редактирования
    history.push('/edit');
  }

  // обработчик кнопки удаления карточки
  function handleClickBtnDelete() {
    localStorage.setItem('cardId', card._id);

    handleOpenPopupConfirm();
  }

  // обработчик клика по короткой ссылке
  function handleClickLink() {
    handleGetCardByLink(card.link);
  }

  function handleClickBtnQR() {
    // положить ссылку в localStorage для QR-кода
    localStorage.setItem('linkForQR', `${PLTDO_URL}/${card.link}`);
    // положить имя/фамилию в localStorage для QR-кода
    localStorage.setItem('nameForQR', `${card.name} ${card.surname}`);
    // положить роль в localStorage для QR-кода
    localStorage.setItem('roleForQR', `${card.position}, ${card.organization}`);

    handleCreateQR();
  }

  return (
    <>
      <tr className="users-table__tr users-table__tr_type_body">
        <td className="users-table__td-body users-table__td-body_type_link">
          <Link
            // type="button"
            to={`/${card.link}`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={handleClickLink}
            className="users-table__link"
          >
            {card.link}
          </Link>
        </td>
        <td className="users-table__td-body">{card.surname}</td>
        <td className="users-table__td-body">{card.name}</td>
        <td className="users-table__td-body">{card.patronymic}</td>
        <td className="users-table__td-body">{card.organization}</td>
        <td className="users-table__td-body">{card.position}</td>
        <td className="users-table__td-body">
          <div className="users-table__td-body_buttons">
            <button
              aria-label="button"
              type="button"
              onClick={handleClickBtnQR}
              className="users-table__btn users-table__btn_type_qr"
            >
              Показать
            </button>
          </div>
        </td>
        <td className="users-table__td-body">
          <div className="users-table__td-body_buttons">
            <button
              aria-label="button"
              type="button"
              onClick={handleClickBtnEdit}
              className="users-table__btn users-table__btn_type_edit"
            />
            <button
              aria-label="button"
              type="button"
              onClick={handleClickBtnDelete}
              className="users-table__btn users-table__btn_type_delete"
            />
          </div>
        </td>
      </tr>
    </>
  );
}

export default UserCard;
